<template>
  <validation-observer ref="credentialsForm" v-slot="{ handleSubmit }">
    <b-form @submit.stop.prevent="handleSubmit()" autocomplete="off" novalidate>
      <b-container>
        <b-row>
          <b-col>
            <h6>Credenciales de usuario</h6>
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="6">
            <validation-provider
              vid="email"
              name="correo electrónico"
              rules="required|email"
              v-slot="validationContext"
            >
              <b-form-group id="email-group" label-for="input-email">
                <b-form-input
                  id="input-email"
                  name="input-email"
                  :state="getValidationState(validationContext)"
                  placeholder="Correo electrónico"
                  v-model="form.email"
                ></b-form-input>
                <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>
          <b-col
            cols="6"
          >
            <span class="error-text">
              {{ errorFormMessage }}
            </span>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <validation-provider name="password" v-slot="validationContext" vid="password" rules="required|password">
              <b-form-group id="password-group" label-for="input-password">
                <b-form-input
                    id="input-password"
                    name="input-password"
                    type="password"
                    v-model="form.password"
                    :state="getValidationState(validationContext)"
                    aria-describedby="input-password"
                    placeholder="Contraseña"
                ></b-form-input>
                <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>
          <b-col>
            <validation-provider name="confirma tu contraseña" rules="required|confirmed:password" vid="repeatedConfirmation" v-slot="validationContext">
              <b-form-group id="input-repeated-password" label-for="input-repeated-password">
              <b-form-input
                  id="input-repeated-password"
                  name="input-repeated-password"
                  type="password"
                  v-model="form.repeatedPassword"
                  :state="getValidationState(validationContext)"
                  aria-describedby="input-repeated-password"
                  placeholder="Confirma tu contraseña"
              ></b-form-input>
              <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>
        </b-row>
      </b-container>
    </b-form>
  </validation-observer>
</template>

<script>

export default {
  name: 'CredentialsForm',
  props: {
    form_prop: {
      type: Object,
      required: true
    },
    errorFormMessage_prop: {
      type: String,
      required: false
    }
  },
  data: function () {
    return {
      form: this.form_prop,
      errorFormMessage: this.errorFormMessage_prop
    }
  },
  watch: {
    errorFormMessage_prop (value) {
      this.errorFormMessage = value
    }
  }
}
</script>

<style lang="less" scoped>
@import "credentialsForm";
</style>
