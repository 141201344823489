<template>
  <div>
    <EmployeeLayout v-if="true">
      <b-card
        :title="'Agregar Personal'"
        class="user-create-card"
        align="left"
      >
        <CredentialsForm
          ref="credentialsForm"
          :form_prop.sync="form"
          :errorFormMessage_prop="errorFormMessage"
        />
        <EmployeeForm
          ref="employeeForm"
          :form_prop.sync="form"
        />
        <b-row class="footer">
          <b-col>
            <b-button variant="secondary" @click="onDiscard" class="d-flex">
              <b-icon-trash></b-icon-trash>
            </b-button>
          </b-col>
          <b-col class="footer-buttons">
            <b-button variant="primary" @click="onSave()">
              Agregar
            </b-button>
          </b-col>
        </b-row>
      </b-card>
    </EmployeeLayout>
    <DiscardChangesModal
      :id="confirmationModalId"
      @onConfirmDiscardModal="onConfirmDiscard"
      @onCancelDiscardModal="onCancelDiscard"
    />
  </div>
</template>
<script>
import EmployeeLayout from '../layout/EmployeeLayout.vue'
import { BIconTrash } from 'bootstrap-vue'
import DiscardChangesModal from '@/components/Modal/DiscardChangesModal'
import CredentialsForm from '@/components/Employee/EmployeeForm/CredentialsForm/CredentialsForm.vue'
import EmployeeForm from '@/components/Employee/EmployeeForm/EmployeeForm.vue'

export default {
  name: 'EmployeeCreate',
  components: {
    EmployeeLayout,
    CredentialsForm,
    EmployeeForm,
    BIconTrash,
    DiscardChangesModal
  },
  data () {
    return {
      form: this.getEmpty(),
      errorFormMessage: ''
    }
  },
  computed: {
    confirmationModalId () {
      return 'confirmation-discard-modal' + this.$store.getters['employee/selected']?.id
    }
  },
  methods: {
    getEmpty () {
      return {
        email: '',
        password: '',
        firstName: '',
        lastName: '',
        technician: {
          nickname: '',
          linkedInProfile: '',
          contactEmail: '',
          address: '',
          postalCode: '',
          phoneNumber: {
            prefix: '',
            number: ''
          },
          location: {
            name: '',
            county: '',
            country: '',
            countryCode: '',
            administrative: '',
            coordinates: {
              latitude: null,
              longitude: null
            },
            service: {
              name: '',
              externalId: ''
            }
          }
        }
      }
    },
    async onSave () {
      const isCredentialsValid = await this.$refs.credentialsForm.$refs.credentialsForm.validate()
      const isEmployeeValid = await this.$refs.employeeForm.$refs.employeeForm.validate()
      if (!isCredentialsValid || !isEmployeeValid) {
        return
      }
      this.lockScreen()
      const employeeToCreate = this.form
      delete employeeToCreate.repeatedPassword
      this.$store.dispatch('employee/create', employeeToCreate)
        .then(() => {
          this.unlockScreen()
          this.$store.dispatch('showConfirmationModal')
          this.$router.push({ name: 'employee-list' })
        })
        .catch((error) => {
          const responseErrorData = error.response.data
          this.errorFormMessage = this.createFormErrors(responseErrorData)

          const responseErrors = this.createFormFieldErrors(responseErrorData)
          this.$refs.credentialsForm.$refs.credentialsForm.setErrors(responseErrors)
        })
    },
    onDiscard () {
      this.$bvModal.show(this.confirmationModalId)
    },
    onCancelDiscard () {
      this.$bvModal.hide(this.confirmationModalId)
    },
    onConfirmDiscard () {
      this.onCancelDiscard()
      this.$router.push({ name: 'employee-list' })
    }
  }
}
</script>

<style lang="less" scoped>
@import "employeeCreate";
</style>
